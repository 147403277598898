body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
}

.menu-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

h2 {
    font-family:monospace;
}

ul {
    list-style: none;
    margin: 20px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

li {
    display: flex;
    font-size: 22px;
    color: black;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
}

.span1 {
    font-size: 18px;
    color: darkred;
}

li:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
}
.buttons{
    text-align: center;
}
.button{
    width: 125px;
    font-family: 'Montserrat', sans-serif;
    color: white;
    border-radius: 5px;
    border: none;
    background: darkred;
    font-size: 19px;
    margin: 3px;
}
.button1{
    width: 125px;
    font-family: 'Montserrat', sans-serif;
    color: white;
    border-radius: 5px;
    border: none;
    background: darkred;
    font-size: 19px;
    margin: 3px;
}
.telefon {
    display: inline-block;
    padding: 10px 20px;
    font-size: 25px;
    border: none;
    margin: 13px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #cf0d0d;
    color: #fff;

  }
  
  .telefon .icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 24px; /* Adjust icon size as needed */
  }
  
/* Media Queries for Responsiveness */

/* Small screens */
@media (max-width: 480px) {
    .menu-container {
        max-width: 100%;
        padding: 10px;
    }
    ul {
        margin: 10px;
        padding: 5px;
    }
    li {
        font-size: 16px;
        padding: 5px;
    }
    .span1,
    .price {
        font-size: 9px;
    }
    .button {
        width: 105px;
        font-size: 16px;
    }
    .button1{
        width: 127px;
        padding-left: 6px;
    }
    .telefon {
        font-size: 18px;
        padding: 8px 16px;
    }
    .telefon .icon {
        margin-right: 8px;
        font-size: 20px;
    }
}

/* Medium screens */
@media (min-width: 481px) and (max-width: 768px) {
    .menu-container {
        padding: 15px;
    }
    ul {
        margin: 15px;
        padding: 8px;
    }
    li {
        font-size: 20px;
        padding: 6px;
    }
    .span1,
    .price {
        font-size: 18px;
    }
    .button {
        width: 115px;
        font-size: 18px;
    }
    .telefon {
        font-size: 22px;
        padding: 10px 18px;
    }
    .telefon .icon {
        font-size: 22px;
    }

}