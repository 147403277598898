
.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    background-color: darkred;
    color: #fff;
    border: none;
    border-radius: 40px;
    padding: 10px 15px;
    cursor: pointer;
    transition: opacity 0.3s;
    opacity: 0;
    display: flex;
    align-items: center;
  }
  
  .back-to-top.visible {
    display: block;
    opacity: 1;
    width: 44px;
  }
  
  .back-to-top:hover {
    background-color: darkred;
  }

  .icon {
    margin-right: 8px;
    font-size: 21px;
  }